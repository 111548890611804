<template>
  <div class="home">

    <h1 class="text-3xl font-bold leading-tight text-gray-900">
      Categories
    </h1>

    <div class="mx-auto w-10/12 grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
      <div v-for="collection in collections" :key="collection.node.id">
        <div class="mb-10 m-2 shadow-lg border-gray-800 bg-gray-100 relative">
          <img class="w-full" :src="collection.node.image.originalSrc" alt=""/>
          <div class="p-4 text-gray-800">
            <router-link :to="{ name: 'Category', params: { handle: collection.node.handle }}"
                         class="font-bold block cursor-pointer hover:underline">{{ collection.node.title }}
            </router-link>
            <span class="text-sm block py-2 border-gray-400 mb-2">{{ collection.node.description }}</span>
          </div>
        </div>
      </div>
    </div>

    <h1 class="text-3xl font-bold leading-tight text-gray-900">
      Products
    </h1>

    <div class="mx-auto w-10/12 grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
      <div v-for="product in products" :key="product.node.id">
        <div class="mb-10 m-2 shadow-lg border-gray-800 bg-gray-100 relative">
          <img class="w-full" :src="product.node.images.edges[0].node.originalSrc" alt=""/>
          <div class="p-4 text-gray-800">
            <router-link :to="{ name: 'Product', params: { handle: product.node.handle }}"
                         class="font-bold block cursor-pointer hover:underline">{{ product.node.title }}
            </router-link>
            <span class="text-sm block py-2 border-gray-400 mb-2">{{ product.node.description }}</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {getCollections, getProducts} from "../assets/js/shopify";

export default {
  data() {
    return {
      collections: false,
      products: false
    }
  },
  async mounted() {
    this.collections = await getCollections()
    this.products = await getProducts()
  }
}
</script>
