<template>
    <div class="mx-auto w-10/12 grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
      <Product v-for="product in collection" :key="product.node.id" v-bind:product="product.node"></Product>
    </div>
</template>

<script>
import Product from '../components/Product.vue'
import {getCollection} from "../assets/js/shopify";

export default {
  components: {
    Product,
  },
  data() {
    return {
      collection: false
    }
  },
  async mounted() {
    this.collection = await getCollection(this.$route.params.handle)
    if (this.collection.length === 0) {
      this.$router.push({name: 'Error404'})
    }
  }
}
</script>