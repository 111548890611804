<template>
  <div class="mb-10 m-2 shadow-lg border-gray-800 bg-gray-100 relative">
    <img class="w-full" :src="product.images.edges[0].node.originalSrc" alt=""/>
    <div class="p-4 text-gray-800">
      <router-link :to="{ name: 'Product', params: { handle: product.handle }}"
                   class="font-bold block cursor-pointer hover:underline">{{ product.title }}
      </router-link>
      <span class="text-sm block py-2 border-gray-400 mb-2">{{ product.description }}</span>
      <button @click="addToCart(product.variants.edges[0].node.id)" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Add to cart
      </button>
    </div>
  </div>
</template>

<script>
import {createCheckout, updateCheckout} from "../assets/js/shopify";

export default {
  props: {
    product: {},
  },
  methods: {
    async addToCart(variantId) {
      if (!localStorage.checkout) {
        let checkout = await createCheckout(variantId, 1)
        localStorage.checkout = checkout.id;
        let lineItems = [{'variantId': variantId, 'quantity': 1}]
        localStorage.lineItems = JSON.stringify(lineItems)
      } else {
        // TODO: Check if product already exist in cart
        let lineItems = JSON.parse(localStorage.lineItems)
        lineItems.push({'variantId': variantId, 'quantity': 1})
        await updateCheckout(localStorage.checkout, lineItems)
        localStorage.lineItems = JSON.stringify(lineItems)
      }
    }
  }
}
</script>