<template>
  <div class="mx-auto w-10/12 grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
    <Product v-if="product" v-bind:product="product"></Product>
  </div>
</template>

<script>
import Product from '../components/Product.vue'
import {getProduct} from "../assets/js/shopify";

export default {
  components: {
    Product,
  },
  data() {
    return {
      product: false
    }
  },
  async mounted() {
    this.product = await getProduct(this.$route.params.handle)
    if (!this.product.id) {
      this.$router.push({name: 'Error404'})
    }
  }
}
</script>