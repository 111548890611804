async function callShopify(query) {
    // const fetchUrl = `https://pnpd.myshopify.com/api/2021-01/graphql.json`;
    const fetchUrl = `https://dev-pnpd.myshopify.com/api/2021-07/graphql.json`;

    const fetchOptions = {
        endpoint: fetchUrl,
        method: "POST",
        headers: {
            // "X-Shopify-Storefront-Access-Token": "7825dd1c7407620fec0b6614d2c5f59b",
            "X-Shopify-Storefront-Access-Token": "9adb24a2f744dbcd99e9abdff47a10d9",
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({query}),
    };

    try {
        return fetch(fetchUrl, fetchOptions).then((response) =>
            response.json(),
        );
    } catch (error) {
        throw new Error("Could not fetch products!");
        // TODO: check if it works
    }
}

export async function getProduct(handle) {
    const query =
        `{
      productByHandle(handle: "${handle}") {
        id
        title
        handle
        description
        images(first: 250) {
          edges {
            node {
              id
              originalSrc
              height
              width
              altText
            }
          }
        }
        variants(first: 250) {
          edges {
            node {
              id
              title
              price
            }
          }
        }
      }
    }`
    ;
    const response = await callShopify(query);
    return response.data.productByHandle
        ? response.data.productByHandle
        : [];
}

export async function getCollection(handle) {
    const query =
        `{
      collectionByHandle(handle: "${handle}") {
        id
        title
        products(first: 250) {
          edges {
            node {
              id
              title
              description
              handle
              images(first: 250) {
                edges {
                  node {
                    id
                    originalSrc
                    height
                    width
                    altText
                  }
                }
              }
              variants(first: 250) {
                edges {
                  node {
                    id
                    title
                    price
                  }
                }
              }
            }
          }
        }
      }
    }`
    ;
    const response = await callShopify(query);
    if (!response.data.collectionByHandle) {
        return []
    }
    return response.data.collectionByHandle.products.edges
        ? response.data.collectionByHandle.products.edges
        : [];
}

export async function getCollections() {
    const query =
        `{
      collections(first:250) {
        edges {
          node {
            id,
            handle,
            title,
            description,
            image {
              originalSrc
            }
          }
        }
      }
    }`
    ;
    const response = await callShopify(query);
    return response.data.collections.edges
        ? response.data.collections.edges
        : [];
}

export async function getProducts() {
    const query =
        `{
      products(first:200) {
        edges {
          node {
            id,
            handle,
            title,
            description,
            images(first:1) {
              edges {
                node {
                  id,
                  originalSrc
                }
              }
            }
          }
        }
      }
    }`
    ;
    const response = await callShopify(query);
    return response.data.products.edges
        ? response.data.products.edges
        : [];
}

export async function createCheckout(variantId, quantity) {
    const query =
        `mutation {
      checkoutCreate(input: {
        lineItems: [{ variantId: "${variantId}", quantity: ${quantity} }]
      }) {
        checkout {
          id
          webUrl
          lineItems(first: 250) {
            edges {
              node {
                title
                quantity
              }
            }
          }
        }
      }
    }`
    ;

    const response = await callShopify(query);

    return response.data.checkoutCreate.checkout
        ? response.data.checkoutCreate.checkout
        : [];
}

export async function updateCheckout(checkout, lineItems) {
    const formattedLineItems = lineItems.map(item => {
        return `{
          variantId: "${item.variantId}",
          quantity:${item.quantity}
        }`
    })
    const query =
        `mutation {
      checkoutLineItemsReplace(
        lineItems: [${formattedLineItems}],
        checkoutId: "${checkout}"
      ) {
        checkout {
          id
          webUrl
          lineItems(first: 250) {
            edges {
              node {
                title
                quantity
              }
            }
          }
        }
      }
    }`
    ;

    const response = await callShopify(query);
    return response.data.checkoutLineItemsReplace.checkout
        ? response.data.checkoutLineItemsReplace.checkout
        : [];
}

export async function getCheckout(checkoutId) {

    const query =
        `{
      node(id: "${checkoutId}") {
        id
        ... on Checkout {
          id
          ready
          currencyCode
          subtotalPrice
          taxesIncluded
          totalTax
          totalPrice
          webUrl
          lineItems(first: 250) {
            edges {
              node {
                id
                title
                quantity
                variant {
                  id
                  price
                  image {
                    originalSrc
                  }
                  product {
                    handle
                  }
                }
              }
            }
          }
        }
      }
    }`
    ;

    const response = await callShopify(query);

    return response.data.node
        ? response.data.node
        : [];
}